@import "https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: antiquewhite;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  display: flex;
  flex-direction: row;
}

.nav-link {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 0px;
  text-decoration: inherit;
  text-transform: uppercase;
  color: black;
}


.navbar-item {
  display: flex;
  justify-content: left;
  align-items: center;
  margin-left: 30px;
  text-decoration: inherit;
  text-transform: uppercase;
  color: black;
}

.header {
  margin-top: 20px;
  display: flex;
  flex: 1;
  flex-direction: column;
}

.header-top {
  display: flex;
  width: 100%;
}

.header-top__logo {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
}

.header-logo {
  color: inherit;
  text-decoration: inherit;
  font-family: monospace;
  font-size: 50px;
}

.header-top__navbar {
  display: flex;
  flex-shrink: 1;
  flex-direction: column;
}

.header-top__seperator {
  width: 100%;
  border: none;
  height: 2px;
  margin-top: 2px;
  background-color: black;
}

.header-top__navigation {
  display: flex;
  flex: auto;
  flex-direction: row;
  justify-content: flex-start;
}

.centered-text {
  text-align: center;
  align-self: center;
  margin: 2px;
}

.pagination {
  display: flex;
  justify-content: center;
}

.pagination li {
  display: block;
 }

.pagination > .active > a{
  color: gray ;
}

.enlarged-photo {
  max-height: 100%;
  max-width: 100%;
  /*align-self: center;*/
 }

.gallery-container {
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: center;
}